<template>
  <div
    class="inline-flex h-24 items-center overflow-hidden rounded-16 py-4 text-12 font-medium"
    :class="badgeVariant.wrapper"
  >
    <WebIcon
      v-if="leftIcon"
      :name="leftIcon"
      class="ml-12 mr-4"
      data-test="left-icon"
      size="12"
    />
    <span
      v-if="showStatusIcon"
      class="ml-[12px] mr-4 inline-block size-[10px] rounded-full"
      :class="statusIconColor"
    />
    <div class="first:pl-12 last:pr-12" data-test="text">
      <slot v-if="$slots.default" />
      <template v-else>
        {{ text }}
      </template>
    </div>
    <WebIcon
      v-if="rightIcon"
      :name="rightIcon"
      class="ml-4 last:mr-12"
      data-test="right-icon"
      size="12"
    />
    <button
      v-if="showClose"
      class="ml-4 inline-flex h-24 items-center justify-center pl-4 pr-8"
      :class="badgeVariant.close"
      tabindex="0"
      @click.stop="emit('close')"
    >
      <WebIcon name="XClose" size="12" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import WebIcon from '@shared/components/icon/index.vue';

const emit = defineEmits(['close']);
const props = defineProps({
  text: { type: String, default: '' },
  variant: { type: String, default: 'primary' },
  ghost: { type: Boolean, default: false },
  leftIcon: { type: String, default: '' },
  rightIcon: { type: String, default: '' },
  showClose: { type: Boolean, default: false },
  showStatusIcon: { type: Boolean, default: false }
});

const badgeVariant = computed(() => {
  const variant = `${props.variant}${props.ghost ? '-ghost' : ''}`;
  return {
    wrapper: `tw-badge-${variant}`,
    close: `tw-badge-${variant}--close`
  };
});

const statusIconColor = computed(() => {
  switch (props.variant) {
    case 'primary':
      return 'bg-primary-500';
    case 'secondary':
      return 'text-neutral-900';
    case 'secondary-500':
      return 'bg-secondary-900';
    case 'success':
      return 'bg-success-500';
    case 'warning':
      return 'bg-warning-500';
    case 'danger':
      return 'bg-error-500';
    case 'grace':
      return 'bg-warning-600';
    case 'passive':
      return 'bg-neutral-500';
    default:
      return '';
  }
});
</script>

<style lang="postcss" scoped>
.tw-badge {
  &-primary {
    @apply bg-primary-500 text-white;

    &--close {
      @apply hover:bg-primary-300;
    }

    &-ghost {
      @apply bg-primary-100 text-primary-500;

      &--close {
        @apply hover:bg-primary-500 hover:text-white;
      }
    }
  }

  &-secondary {
    @apply bg-neutral-900 text-white;

    &--close {
      @apply hover:bg-neutral-300 hover:text-neutral-900;
    }

    &-ghost {
      @apply bg-neutral-100 text-neutral-900;

      &--close {
        @apply hover:bg-neutral-900 hover:text-white;
      }
    }

    &-500 {
      @apply bg-secondary-500 text-white;

      &--close {
        @apply hover:bg-secondary-300;
      }

      &-ghost {
        @apply bg-secondary-100 text-secondary-500;

        &--close {
          @apply hover:bg-secondary-500 hover:text-white;
        }
      }
    }
  }

  &-success {
    @apply bg-success-500 text-white;

    &--close {
      @apply hover:bg-success-300 hover:text-success-500;
    }

    &-ghost {
      @apply bg-success-100 text-success-500;

      &--close {
        @apply hover:bg-success-500 hover:text-white;
      }
    }
  }

  &-warning {
    @apply bg-warning-500 text-white;

    &--close {
      @apply hover:bg-warning-700 hover:text-white;
    }

    &-ghost {
      @apply bg-warning-100 text-warning-500;

      &--close {
        @apply hover:bg-warning-500 hover:text-white;
      }
    }
  }

  &-error {
    @apply bg-error-500 text-white;

    &--close {
      @apply hover:bg-error-300;
    }

    &-ghost {
      @apply bg-error-100 text-error-500;

      &--close {
        @apply hover:bg-error-500 hover:text-white;
      }
    }
  }

  &-passive {
    &-ghost {
      @apply bg-neutral-100 text-neutral-500;
    }
  }
}
</style>
