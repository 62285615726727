<template>
  <div
    ref="inputRef"
    v-click-outside="closeList"
    class="mb-16 min-w-[210px]"
  >
    <div class="tw-select relative">
      <div
        v-if="label && !hideLabel"
        :style="labelStyle"
        class="mb-8 flex items-center gap-8 text-14 font-medium text-neutral-900"
        :class="{ [`tw-select-label-${props.size}`]: !!props.size }"
      >
        {{ label }}
        <slot name="labelIcon" />
        <template v-if="labelHint">
          <WebTooltip
            class="cursor-pointer"
            placement="right"
            :content="labelHint"
            :max-width="220"
          >
            <WebIcon
              class="text-neutral-300"
              name="Question"
              size="20"
            />
          </WebTooltip>
        </template>
      </div>
      <WebPopover
        trigger="manual"
        placement="bottom"
        theme="select-popover"
        animation="shift-away"
        :arrow="false"
        :popper-options="popperOptions"
        :duration="[200, 150]"
        @create="getPopoverInstance"
        @click="toggleList"
      >
        <div
          class="tw-select-normal flex items-center rounded-8 border border-solid px-8"
          data-test="input"
          :class="selectClass"
          v-bind="{ [disabled ? '' : 'tabindex']: 0 }"
          @[arrowDownEvent]="onKeyDownSelect"
        >
          <select
            v-model="inputValue"
            class="tw-select-normal-input hidden"
            aria-readonly="true"
            :name="name"
            :multiple="multiple"
            @change="handleChange"
            @blur="handleBlur"
          >
            <option
              v-for="option of options"
              :key="(option.value as string)"
              :value="option.value"
              :selected="!!getSelectedValue(option)"
            >
              {{ option.label }}
            </option>
          </select>

          <div class="tw-selections flex flex-1 flex-wrap items-center truncate">
            <span v-if="textLeft && selections.length" class="px-8 text-14 font-medium text-neutral-900">
              {{ textLeft }}
            </span>
            <span v-if="!selections.length" class="px-8 text-neutral-500">
              {{ placeholder || translate('generate.common.select', locale) }}
            </span>
            <template v-else-if="multiple">
              <WebBadge
                v-for="item of selections.slice(0, maxTagCount || selections.length)"
                :key="(item.value as string)"
                ghost
                :variant="hasError ? 'error' : 'primary'"
                :show-close="!disabled && !item.noDelete"
                class="m-[1px] mr-8 last:mr-[1px]"
                :text="getTagLabel(item)"
                @close="removeFromSelection(item)"
              />
              <WebBadge
                v-if="maxTagCount && selections.length > maxTagCount"
                class="m-[1px]"
                :variant="hasError ? 'error' : 'primary'"
                :text="`+${selections.length - maxTagCount}${` ${maxTagCountText || translate('generate.common.more', locale)}`}`"
              />
            </template>
            <template v-else>
              <span
                v-if="selections[0]?.image"
                class="ml-8 flex size-24 shrink-0 grow-0 basis-24 overflow-hidden rounded-[50%] align-middle"
                :class="[selectionsImageClass, { 'opacity-50': disabled }]"
              >
                <img
                  class="object-cover"
                  :src="getSelectedValue(selections[0])?.image"
                  alt="SelectedIcon"
                />
              </span>

              <span
                class="tw-selections__text ml-8 select-none truncate"
                :class="textColorClass"
              >
                {{
                  showSelectedValueAsLabel
                    ? getSelectedValue(selections[0])?.value
                    : getSelectedValue(selections[0])?.label
                }}
              </span>
            </template>
          </div>
          <WebIcon
            v-if="!selections.length && showRemove"
            name="XClose"
            class="w-16 text-neutral-500"
            @click="handleRemove()"
          />
          <template v-else>
            <!-- SmallArrowUp -->
            <svg
              v-if="showList"
              version="1.1"
              role="presentation"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="20"
              height="20"
              class="tw-select__arrow-up"
              :class="textColorClass"
            >
              <path
                d="M15.8727 15.0025L11.9927 11.1225L8.11266 15.0025C7.72266 15.3925 7.09266 15.3925 6.70266 15.0025C6.31266 14.6125 6.31266 13.9825 6.70266 13.5925L11.2927 9.00246C11.6827 8.61246 12.3127 8.61246 12.7027 9.00246L17.2927 13.5925C17.6827 13.9825 17.6827 14.6125 17.2927 15.0025C16.9027 15.3825 16.2627 15.3925 15.8727 15.0025Z"
                fill="currentColor"
              />
            </svg>
            <!-- /SmallArrowUp -->
            <!-- SmallArrowDown -->
            <svg
              v-else
              version="1.1"
              role="presentation"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="20"
              height="20"
              class="tw-select__arrow-down"
              :class="textColorClass"
            >
              <path
                d="M8.12266 9.00246L12.0027 12.8825L15.8827 9.00246C16.2727 8.61246 16.9027 8.61246 17.2927 9.00246C17.6827 9.39246 17.6827 10.0225 17.2927 10.4125L12.7027 15.0025C12.3127 15.3925 11.6827 15.3925 11.2927 15.0025L6.70266 10.4125C6.31266 10.0225 6.31266 9.39246 6.70266 9.00246C7.09266 8.62246 7.73266 8.61246 8.12266 9.00246Z"
                fill="currentColor"
              />
            </svg>
            <!-- /SmallArrowDown -->
          </template>
        </div>
        <template #content>
          <div
            ref="itemRef"
            data-test="select-list"
            class="tw-dropdown_content top-full z-10 flex max-h-[256px] w-full flex-col rounded-8 bg-white p-8 shadow-md"
            :class="[dropdownContentClass, { '!w-fit': fitContent }]"
          >
            <div
              v-if="loading"
              data-test="spinner"
              class="flex items-center justify-center p-32 text-neutral-500"
              :class="{ 'pb-40': !!$slots.subcontent }"
            >
              <WebSpinner
                size="32"
                stroke="3px"
                color="transparent"
                background="neutral-500"
              />
            </div>

            <div v-else-if="!props.options.length" class="p-20 text-center text-14 text-neutral-500">
              <template v-if="!$slots.empty">
                {{ translate('generate.common.noResult', locale) }}
              </template>
              <slot name="empty" />
            </div>

            <template v-else>
              <div v-if="showSearch">
                <WebInput
                  v-model="keyword"
                  :page-options="pageOptions"
                  :locale="locale"
                  name="dropdown_search"
                  :placeholder="translate('generate.common.search', locale)"
                  type="search"
                  class-name="mb-8"
                >
                  <template #left>
                    <!-- SearchNormal icon -->
                    <svg
                      version="1.1"
                      role="presentation"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      class="mr-8 text-neutral-500"
                    >
                      <path
                        d="M21.7002 20.3002L18.0002 16.6002C21.1002 12.7002 20.5002 7.00024 16.6002 3.90024C12.7002 0.800239 7.00024 1.50024 3.90024 5.30024C0.800239 9.20024 1.50024 14.9002 5.30024 18.0002C8.60024 20.6002 13.3002 20.6002 16.6002 18.0002L20.3002 21.7002C20.7002 22.1002 21.3002 22.1002 21.7002 21.7002C22.1002 21.3002 22.1002 20.7002 21.7002 20.3002ZM11.0002 18.0002C7.10024 18.0002 4.00024 14.9002 4.00024 11.0002C4.00024 7.10024 7.10024 4.00024 11.0002 4.00024C14.9002 4.00024 18.0002 7.10024 18.0002 11.0002C18.0002 14.9002 14.9002 18.0002 11.0002 18.0002Z"
                        fill="currentColor"
                      />
                    </svg>
                    <!-- /SearchNormal icon -->
                  </template>
                </WebInput>
              </div>

              <div class="overflow-auto">
                <!-- Search Input -->
                <div
                  v-if="keyword && !selectedList.length && !optionsList.length"
                  class="p-20 text-center text-14 text-neutral-500"
                >
                  <template v-if="!$slots.notfound">
                    {{ translate('generate.common.noResult', locale) }}
                  </template>
                  <slot name="notfound" />
                </div>

                <!-- Selected List (if selectedOptionsComesFirst is true) -->
                <div
                  v-if="selectedList.length && selectedOptionsComesFirst"
                  :class="{ 'border-b-[1px] border-b-neutral-200 last:border-b-0': multiple }"
                >
                  <Button
                    v-for="item of selectedList"
                    :key="(item.value as string)"
                    full
                    type="button"
                    class="tw-select-item tw-select-item-selected"
                    :class="{ multiple, 'last:!rounded-b-[0]': multiple && optionsList.length }"
                    variant="text"
                    data-test="select-item"
                    :tabindex="0"
                    :disabled="item.disabled || item.noDelete"
                    @click="handleSelect(item)"
                  >
                    <WebCheckmark
                      v-if="multiple"
                      checked
                      by-wrapper
                      class="mr-8"
                      :disabled="item.disabled || item.noDelete"
                    />
                    <span
                      v-if="item.image"
                      class="mr-[10px] flex size-24 overflow-hidden rounded-[50%] align-middle"
                      :class="{ 'opacity-50': item.disabled }"
                    >
                      <img class="object-cover" :src="item.image" />
                    </span>
                    <span class="flex-1 truncate text-14 font-medium">{{ item.label }}</span>
                    <WebIcon v-if="item.icon" :name="item.icon" />
                    <WebIcon v-else-if="!multiple && !hideSelectedOptionCheck" name="Tick2" />
                  </Button>
                </div>

                <!-- Unselected List (if selectedOptionsComesFirst is false all options that includes selected is listed here) -->
                <Button
                  v-for="item of optionsList"
                  :key="(item.value as string)"
                  full
                  type="button"
                  class="tw-select-item"
                  :class="{
                    multiple,
                    'first-of-type:!rounded-t-[0]': multiple && selections.length,
                    'tw-select-item-selected': !!getSelectedValue(item) && !selectedOptionsComesFirst
                  }"
                  variant="text"
                  data-test="select-item"
                  :tabindex="0"
                  :disabled="item.disabled"
                  :title="optionsNativeTooltip && item.label || undefined"
                  @click="handleSelect(item)"
                >
                  <WebCheckmark
                    v-if="multiple"
                    by-wrapper
                    class="mr-8"
                    :disabled="item.disabled"
                  />
                  <span
                    v-if="item.image"
                    class="mr-[10px] flex size-24 overflow-hidden rounded-[50%] align-middle"
                    :class="{ 'opacity-50': item.disabled }"
                  >
                    <img
                      class="object-cover"
                      :src="item.image"
                      alt="SelectIcon"
                    />
                  </span>
                  <span class="flex-1 truncate text-14 font-medium">{{ item.label }}</span>
                  <WebIcon v-if="item.icon" :name="item.icon" />
                  <WebIcon v-else-if="!multiple && !hideSelectedOptionCheck && !!getSelectedValue(item)" name="Tick2" />
                </Button>
              </div>
            </template>

            <div v-if="$slots.subcontent" class="border-t-[1px] border-t-neutral-200 pt-8 text-14">
              <slot name="subcontent" />
            </div>
          </div>
        </template>
      </WebPopover>
    </div>

    <span
      v-if="hint || errorMessage || (meta.dirty && meta.valid && (successMessage || hint))"
      data-test="select-hint"
      class="mt-8 block"
      :class="[textColorClass, { 'text-12': size === 'md', 'text-10': size === 'sm' }]"
    >
      <template v-if="errorMessage || (meta.dirty && meta.valid)">
        {{ errorMessage || successMessage || hint }}
      </template>
      <template v-else>{{ hint }}</template>
    </span>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onBeforeUnmount, nextTick, watch, toRef } from 'vue';
import { useField } from 'vee-validate';
import { plainChars } from '@shared/utils/helpers';
import type { PageOptions } from '@shared/types/model';
import { useTranslate } from '@shared/composable/useTranslate';
import type { SelectOption } from './types';
import { popperOptions } from './utils';
import WebSpinner from '@shared/components/spinner/index.vue';
import WebInput from '@shared/components/input/index.vue';
import WebIcon from '@shared/components/icon/index.vue';
import WebPopover from '@shared/components/popover/index.vue';
import WebBadge from '@shared/components/badge/index.vue';
import WebCheckmark from '@shared/components/checkmark/index.vue';
import WebTooltip from '@shared/components/tooltip/index.vue';

type SelectProp = {
  name: string;
  options: SelectOption[];
  label?: string;
  labelHint?: string;
  hideLabel?: boolean;
  labelStyle: Record<string, any>;
  hint?: string;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  multiple?: boolean;
  error?: boolean;
  rules?: string;
  successMessage?: string;
  modelValue?: null | string | number | number[] | string[];
  maxTagCount?: number;
  maxTagCountText?: string;
  maxTagTextLength?: number;
  showSearch?: boolean;
  noBorder?: boolean;
  noFocusShadow?: boolean;
  size?: string;
  textLeft?: string;
  showRemove?: boolean;
  fitContent?: boolean;
  hideSelectedOptionCheck?: boolean;
  pageOptions?: PageOptions;
  selectedOptionsComesFirst?: boolean;
  showSelectedValueAsLabel?: boolean;
  dropdownContentClass?: string;
  selectionsImageClass?: string;
  locale?: string;
  optionsNativeTooltip?: boolean;
};

const emit = defineEmits(['update:modelValue', 'error', 'selected', 'removed']);
const props = withDefaults(defineProps<SelectProp>(), {
  label: '',
  labelHint: '',
  hideLabel: false,
  labelStyle: {} as any,
  hint: '',
  placeholder: '',
  rules: '',
  successMessage: '',
  modelValue: () => [],
  maxTagCount: 0,
  maxTagCountText: '',
  maxTagTextLength: 0,
  size: 'md',
  textLeft: '',
  fitContent: false,
  hideSelectedOptionCheck: false,
  pageOptions: () => ({} as PageOptions),
  selectedOptionsComesFirst: false,
  showSelectedValueAsLabel: false,
  dropdownContentClass: '',
  selectionsImageClass: '',
  locale: '',
  optionsNativeTooltip: false,
});

const { translate } = useTranslate();

const inputRef = ref<HTMLElement>();
const itemRef = ref<HTMLElement>();
const nameRef = toRef(props, 'name');
const rulesRef = toRef(props, 'rules');
const showList = ref(false);
const hasError = ref(props.error);
const selections = ref<SelectOption[]>([]);
const keyword = ref('');
const popoverInstance = ref<any>(undefined);

const optionsList = computed(() => {
  const list = props.options.filter((item) => {
    if (!props.selectedOptionsComesFirst) return true;
    return !selections.value.find((selected) => selected.value === item.value);
  });

  if (!props.showSearch || !keyword.value) return list;

  const pattern = new RegExp(plainChars(keyword.value.replace(/(\+)/g, '\\$1')).toLowerCase(), 'g');
  return list.filter((item) => {
    return pattern.test(plainChars(item.label).toLowerCase());
  });
});

const selectedList = computed(() => {
  const list = selections.value;

  if (!props.showSearch || !keyword.value) return list;
  const pattern = new RegExp(plainChars(keyword.value.replace(/(\+)/g, '\\$1')).toLowerCase(), 'g');

  return list.filter((item) => {
    return pattern.test(plainChars(item.label).toLocaleLowerCase());
  });
});

const selectClass = computed(() => {
  return {
    'tw-select-error': hasError.value,
    'tw-select-disabled': props.disabled,
    'h-[40px] py-8': !props.multiple && props.size != 'sm',
    'min-h-[40px]': props.multiple && props.size != 'sm',
    'py-[6px]': props.multiple && selections.value.length,
    'py-[7px]': props.multiple && !selections.value.length,
    [`tw-${props.size}`]: !!props.size,
    noBorder: props.noBorder,
    noFocusShadow: props.noFocusShadow
  };
});

const textColorClass = computed(() => ({
  'text-neutral-500': !hasError.value && !props.disabled,
  'text-error-500': hasError.value && !props.disabled,
  'text-neutral-300': props.disabled
}));

const selectStyle = computed(() => {
  return {
    input: {
      borderColor: props.pageOptions?.colors?.theme?.[0]
    },
    selectedOption: {
      bgColor: props.pageOptions?.colors?.theme?.[0],
      color: props.pageOptions?.colors?.text?.[2]
    }
  };
});

const arrowDownEvent = computed(() => {
  return showList.value ? '' : 'keydown';
});

// Virtual validation with hidden select
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta
} = useField(nameRef, rulesRef, {
  initialValue: getDefaultValues()
});

watch(
  () => errorMessage.value,
  (val) => {
    if (val) hasError.value = true;
    else hasError.value = false;
  }
);

watch(
  () => selections.value,
  (val) => {
    (inputValue.value as any) = props.multiple ? val.map((item) => item.value as string) : val[0]?.value;
  },
  { deep: true }
);

watch(
  () => props.modelValue,
  (val) => {
    const modelVal = props.multiple ? [...(val as any[])].sort().join(',') : val;
    const currentVal = props.multiple ? [...(inputValue.value as string)].sort().join(',') : inputValue.value;

    if (modelVal !== currentVal) {
      getDefaultValues();
    }
  }
);

watch(
  () => inputValue.value,
  (val) => emit('update:modelValue', val),
  { deep: true }
);

function onKeyDownSelect(e: any) {
  const key = e.key;
  if ((/(ArrowDown|Enter)/.test(key) || key === ' ') && !showList.value) {
    e.preventDefault();
    openListAndFocus();
  }
}

function getTagLabel(item: SelectOption) {
  const textLength = props.maxTagTextLength;
  if (!textLength) return item.label;
  return props.maxTagTextLength < item.label.length ? `${item.label.slice(0, textLength)}...` : item.label;
}

function getDefaultValues() {
  const list = props.multiple
    ? (props.modelValue as string[]).filter((value) => !!props.options.find((item) => item.value === value))
    : props.options.find((item) => item.value === props.modelValue)?.value;

  selections.value = props.options.filter((item) => {
    if (props.multiple) {
      return ((props.modelValue || []) as string[])?.includes(item.value as string);
    } else {
      return item.value === props.modelValue;
    }
  });

  return list;
}

function getSelectedValue(option: SelectOption) {
  return selections.value.find((item) => item.value === option?.value);
}

function addToSelection(option: SelectOption) {
  const isSelected = getSelectedValue(option);
  if (!isSelected) {
    selections.value.push(option);
  }
}

function removeFromSelection(option: SelectOption, selectionItem?: SelectOption) {
  const isSelected = selectionItem || getSelectedValue(option);

  if (isSelected) {
    const indexSelect = selections.value.indexOf(isSelected);
    selections.value.splice(indexSelect, 1);
  }
}

function emptySelections() {
  selections.value = [];
}

function scrollToSelectedOption() {
  if (!selections.value.length) return;
  nextTick(() => {
    const selectedItem = itemRef.value?.querySelector('button.tw-select-item-selected') as HTMLElement;
    if (selectedItem) selectedItem?.scrollIntoView({ block: 'center' });
  })
}

function toggleSelect(isSelected: any, value: any) {
  if (isSelected) {
    removeFromSelection(value, isSelected);
  } else {
    addToSelection(value);
  }
}

function handleSelect(value: SelectOption) {
  if (!props.multiple) {
    emptySelections();
    addToSelection(value);
    closeList();
    emit('selected', value);
    return;
  }
  const isSelected = getSelectedValue(value);
  toggleSelect(isSelected, value);
  emit('selected', value);
}

function handleRemove() {
  emit('removed');
}

function getPopoverInstance(instance: any) {
  popoverInstance.value = instance;
}

function openList() {
  if (props.disabled) return false;
  popoverInstance.value.show();
  if (!props.selectedOptionsComesFirst) scrollToSelectedOption();
  showList.value = true;

  document.addEventListener('keydown', onKeyDown);
}

function closeList() {
  popoverInstance.value.hide();
  showList.value = false;
  keyword.value = '';

  document.removeEventListener('keydown', onKeyDown);
}

function toggleList() {
  if (showList.value) {
    closeList();
  } else {
    openList();
  }
}

function onKeyDown(e: any) {
  if (e.key === 'Escape') closeList();
}

function openListAndFocus() {
  openList();
  if (import.meta.env.MODE !== 'test') {
    nextTick(() => {
      const selectedItem = itemRef.value?.querySelector('button.tw-select-item-selected') as HTMLElement;
      if (selectedItem) selectedItem?.focus();
    });
  }
}

function removeKeyListeners() {
  document.removeEventListener('keydown', onKeyDown);
}

onBeforeUnmount(() => {
  removeKeyListeners();
});
</script>

<style lang="postcss" scoped>
@import 'tippy.js/animations/shift-away.css';
.tw-select {
  --select-focusBorderColor: v-bind('selectStyle.input.borderColor');
  --select-selectedOptionBgColor: v-bind('selectStyle.selectedOption.bgColor');
  --select-selectedOptionColor: v-bind('selectStyle.selectedOption.color');
  &-normal {
    @apply relative border-transparent bg-white transition-shadow focus-within:shadow-none focus-visible:outline-none;

    &:focus-within:not(.tw-select-error) {
      border-color: var(--select-focusBorderColor, '#56C4D6');
    }

    &:not(.noBorder, .tw-select-error):not(:focus-within) {
      @apply border-neutral-200;
    }

    &.noFocusShadow {
      @apply focus-within:border-white focus-within:shadow-white;
    }

    &-input {
      @apply pointer-events-none absolute bottom-0 left-0 right-0 top-0 opacity-0;
    }
  }

  &-error {
    @apply border-error-500 focus-within:border-error-500 focus-within:shadow-none;

    &-input {
      @apply text-error-500;
    }
  }

  &-disabled {
    @apply border-neutral-200 bg-neutral-100 text-neutral-100;

    &-input {
      @apply text-neutral-300 placeholder:text-neutral-300;
    }
  }

  &-item {
    @apply flex flex-[0_0_auto] h-auto max-h-[inherit] min-h-[48px] w-full cursor-pointer
      items-center rounded-8 p-12 text-left text-neutral-900 hover:no-underline
      focus:border-0 focus-visible:outline-none disabled:text-neutral-300;

    &:not(:disabled) {
      @apply hover:bg-neutral-100 hover:text-neutral-900 focus:bg-neutral-100 focus:text-neutral-900;
    }

    &-selected {
      color: var(--select-selectedOptionColor, '#56C4D6');

      &:not(:disabled) {
        &:hover,
        &:focus {
          background-color: var(--select-selectedOptionBgColor, '#F5FEFF');
          color: var(--select-selectedOptionColor, '#56C4D6');
        }
        &:not(.multiple) {
          background-color: var(--select-selectedOptionBgColor, '#F5FEFF');
        }
      }
    }
  }
  .tw-sm {
    @apply py-[3px] text-12;
  }
  .tw-md {
    @apply text-14;
  }
}
::-webkit-scrollbar {
  @apply w-4;
}

::-webkit-scrollbar-thumb {
  @apply rounded-8 bg-neutral-200;
}

.tw-select-label-sm {
  @apply text-12 font-normal;
}

:deep([data-theme='select-popover']) {
  @apply text-neutral-900;
  .tippy-content {
    @apply !p-0;
  }
}
</style>
