<template>
  <Tippy
    ref="tippyRef"
    v-bind="$attrs"
    :theme="theme"
    :arrow="arrowAttr"
  >
    <template v-for="(_, slotName) in $slots" #[slotName]="slotProps">
      <slot :name="slotName" v-bind="slotProps" />
    </template>
  </Tippy>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { Tippy } from 'vue-tippy';

const props = defineProps({
  light: { type: Boolean, default: false },
  arrow: { type: Boolean, default: true }
});

const tippyRef = ref();
const theme = computed(() => (props.light ? 'light' : 'dark'));

const arrowSvg = `<svg width="16" height="8" viewBox="0 0 16 8" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8L16 8L9.41421 1.41421C8.63317 0.633164 7.36684 0.633164 6.58579 1.41421L0 8Z"/>
  </svg>`;
const arrowAttr = computed(() => (props.arrow ? arrowSvg : false));

defineExpose({ tippyRef });
</script>

<style lang="postcss">
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/dist/svg-arrow.css';

.tippy-box {
  @apply bg-transparent;
}

.tippy-content {
  @apply max-w-[400px] rounded-8 p-12 text-12;
}

[data-theme='dark'] {
  .tippy-content {
    @apply bg-primary-900 text-white;
  }
  .tippy-svg-arrow {
    svg {
      @apply fill-primary-900;
    }
  }
}

[data-theme='light'] {
  .tippy-content {
    @apply bg-white text-neutral-900 shadow-md;
  }
  .tippy-svg-arrow {
    @apply z-10 fill-white;
    svg {
      path {
        @apply drop-shadow-md;
      }
    }
  }
}
</style>
