/**
 * This popper modifier makes popper the same width as the reference.
 * https://popper.js.org/docs/v2/modifiers/community-modifiers/
 */
const sameWidthModifier = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }: any) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }: any) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  }
};

export const popperOptions = {
  strategy: 'fixed',
  modifiers: [sameWidthModifier]
};
